import React from 'react';
import { GetServerSideProps } from 'next';
import Head from 'next/head';

import AuthoredPageRoutes from '@/components/authored-page-routes/authored-page-routes';
import { PreRenderProps } from '@/pages/_app';
import { getViewDefinition } from '@/ssr/view-definition-page-query';
import { fetchDataAndHandleErrors } from '@/utils/ssr-utils';

import { getMeterCarrier, measureDuration, MeterCarrier } from '@/telemetry-sdk';
import { OtelCarrier } from '@/telemetry-sdk/csr';

type PageProps = PreRenderProps & { meterCarrier: MeterCarrier };

export const getServerSideProps: GetServerSideProps<PageProps> = async (context) => {
  const { result: data, duration: backendFetchDuration } = await measureDuration(() =>
    fetchDataAndHandleErrors(context, getViewDefinition, undefined)
  );
  const meterCarrier = getMeterCarrier(backendFetchDuration);

  // Do not change PageProps type dramatically (only add new props)
  // Some SDUI components (Masthead) reads the props manually via NextData in the DOM, not via component props.
  // Changing the type will break header rendering, specifically the bagCount and favourites.
  const props: PageProps = {
    ...data,
    meterCarrier,
  };
  return { props };
};

const Home: React.FC<PageProps> = (props) => {
  const { meterCarrier, ...data } = props;

  return (
    <>
      <Head>
        <OtelCarrier meterCarrier={meterCarrier} />
      </Head>
      <AuthoredPageRoutes {...(data as any)} />
    </>
  );
};

export default Home;
